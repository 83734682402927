import {ModalBase} from "../modal_base";
import {AjaxPromise} from "../../shared/ajax_utilities";

export class ListAssignModal extends ModalBase {
    constructor(assign) {
        super(`list_${assign ? 'assign' : 'unassign'}_modal`);
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            const is_assigned = ( $modalTriggerBtn.attr('data-is-assigned') === '1' ) ? '0' : '1';

            loading('start', $modalTriggerBtn);

            try {
                const res = await AjaxPromise({
                    'method': 'POST',
                    'url': app.CACHE.URL_AJAX + 'rep_che/assign/' + $modalTriggerBtn.attr('data-id'),
                    'data': {
                        'is_assigned': is_assigned,
                        'operator_id': $modalTriggerBtn.attr('data-operator-id'),
                        'vehicle_type_id': $modalTriggerBtn.attr('data-vehicle-type-id'),
                        'text': $modalTriggerBtn.attr('data-text')
                    }
                });

                if( res.status === 'success' ) {
                    window.location.reload();
                } else {
                    loading('end', $modalTriggerBtn);
                    console.log(res);
                    alert("There was an error, contact administrator");
                }
            } catch(err) {
                loading('end', $modalTriggerBtn);
                console.log(err);
                alert("There was an error, contact administrator");
            }
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}